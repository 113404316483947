@import '../../variables';

.btn {
    cursor: pointer;
    height: auto;
    font-size: 20px;
    padding: 12px 25px 12px;
    border-radius: 30px;
    color: $background;
    background: $primary-color;
    border: solid 1px $primary-color;
    text-transform: uppercase;
    letter-spacing: 1px;
    line-height: 1;
    transition: all 0.2s ease;
    outline: 0;
    &:hover {
      text-decoration: none;
      background: $background-dark;
      border-color: $primary-color;
      transition: all 0.2s ease;
      color: $primary-color;
      box-shadow: $background-dark -1px 3px 6px;
      cursor: pointer;
    }
    
    &.secondary {
      font-size: 20px;
      background: none;
      color: $font-color;
      box-shadow: none;
  
      &:hover {
        background: $background-dark;;
        color: $primary-color;
        border-color: $primary-color;
      }
    }
    &.contrast {
      color: $background;
      border-color: $background;
  
      &:hover {
        background-color: $background;
        color: black;
      }
    }
  }
  
  @media only screen and (max-width: 600px) {
    .btn {
      font-size: 16px;
      &.secondary {
        font-size: 16px;
      }
    }
  }