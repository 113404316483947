@import "./_variables.scss";


@font-face {
  font-family: 'AppleGothicNeo';
  src: local('AppleGothicNeo'), url(./fonts/SourceSansPro-SemiBold.ttf) format('opentype');
  font-display: swap;
}
@font-face {
  font-family: 'AppleGothicNeo Regular';
  src: local('AppleGothicNeo Regular'), url(./fonts/SourceSansPro-Regular.ttf) format('opentype');
  font-display: swap;
}

body {
  font-family: "AppleGothicNeo";
    background-color: $background;
}

.ant-drawer-wrapper-body {
  background-color: #192B3C;
}

.anticon svg {
  display: none;
}

p {
  font-family: 'AppleGothicNeo Regular';
  margin-bottom: 0px;
  font-size: 17px;
  color: $font-color;
}

a {
  text-decoration: none;
  font-family: 'AppleGothicNeo Regular';
  margin-bottom: 0px;
  font-size: 17px;
  color: $font-color;
}

a:hover {
  color: $font-color;
}

h1, h2, h3 {
  color: $font-color;
}

h1 {
  font-size: 50px;
  font-weight: 500;
}

h2 {
  font-size: 30px;
  color: $primary-color;
  font-weight: 400;
  line-height: 33px;
}

h3 {
  font-family: 'AppleGothicNeo Regular';
  font-size: 25px;
  font-weight: 250;
}

b {
  font-family: 'AppleGothicNeo';
  margin-bottom: 0px;
  font-size: 18px;
}


* {
  margin: 0;
}

@media only screen and (max-width: 700px) {
  p {
    font-size: 15px;
    line-height: 22px !important;
  }

  h1 {
    font-size: 35px;
    font-weight: 500;
    line-height: 46px !important;
  }
  
  h2 {
    font-size: 25px;
    color: $primary-color;
    font-weight: 400;
  }
  
  h3 {
    font-size: 20px;
    font-weight: 100;
  }
}
